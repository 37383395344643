
import { formatDate } from '@/utils/date'
import request from '@/utils/request'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
import { defineComponent, nextTick } from 'vue'
import QRCode from 'qrcode'

export default defineComponent({
	components: {},
	data() {
		const end = new Date()
		const start = new Date()
		start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
		return {
			defaultDate: end,
			shortcuts: [
				{
					text: '最近1天',
					value: () => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
						return [start, end]
					},
				},
				{
					text: '最近3天',
					value: () => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 3)
						return [start, end]
					},
				},
				{
					text: '最近1周',
					value: () => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
						return [start, end]
					},
				},
				{
					text: '最近1个月',
					value: () => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
						return [start, end]
					},
				},
				{
					text: '最近3个月',
					value: () => {
						const end = new Date()
						const start = new Date()
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
						return [start, end]
					},
				},
			],
			searchForm: {
				keyword: '',
				type: 'logistics_code',
				code: '',
				date: [] as any,
				packageIds: [],
			},
			routerDialog: {
				visible: false,
				code: '',
				router: [],
			},
			isLoading: false,
			selectedRows: [],
			downloadLoading: false,
			pageConfig: {
				size: 20,
				current_page: 1,
				total: 0,
			},
			packageList: [],
			paymentSummary: {
				package_ids: [],
				total_package: 0,
				total_price: 0,
			},
			paymentDialog: {
				visible: false,
				user_balance: 9999.5,
				pay_order_id: 0,
				pay_order_amount: 1520.58,
				paymentMethod: [
					{
						title: '格布钱包',
						note: '',
						id: 'balance',
						disabled: false,
						icon: 'https://res-cn.public.gblinker.com/gblinker/wechat_mini/images/789fc8489bf47b2aaa11675150919852.svg',
					},
					{
						title: '微信支付',
						id: 'native_wechat',
						note: '',
						disabled: false,
						icon: 'https://res-cn.public.gblinker.com/gblinker/wechat_mini/images/789fc8489bf47b2aaa11675150919854.svg',
					},
				],
				selectPayment: 'balance',
				timer: 0,
				qrcode: '',
				showQrcode: false,
			},
		}
	},
	async created() {
		const {
			keyword,
			page_size,
			code = '',
			page_index,
			type,
			start_time = 0,
			end_time = 0,
		}: any = this.$route.query
		if (page_size) {
			this.searchForm.keyword = keyword
			this.searchForm.code = code
			this.searchForm.type = type
			this.searchForm.date = Number(start_time)
				? [new Date(start_time * 1000), new Date(end_time * 1000)]
				: null
			this.pageConfig.size = Number(page_size ?? 20)
			this.pageConfig.current_page = Number(page_index ?? 1)
		}
		await this.searchPackages()
	},
	methods: {
		handleSelectionChange(val: any) {
			this.selectedRows = val
			this.searchForm.packageIds = val.map((v: any) => v.id)
			this.paymentSummary.package_ids = []
		},
		handelSizeChange(val: number) {
			this.pageConfig.size = val
			this.searchPackages(true)
		},
		formatDate(val: number) {
			return formatDate('YYYY-MM-DD h:i:s', val)
		},
		async searchPackages(isReset = false) {
			if (isReset) {
				this.pageConfig.current_page = 1
			}
			this.paymentSummary.package_ids = []
			const params = {
				page_size: this.pageConfig.size,
				...this.getCondition(),
				page_index: this.pageConfig.current_page,
				keyword: this.searchForm.keyword,
				code: this.searchForm.code,
				type: this.searchForm.type,
			}
			this.selectedRows = []
			const { data } = await request
				.get('/v1/merchants/unpaid-packages', {
					params: params,
				})
				.catch(() => {
					return {
						data: null,
					}
				})
				.finally(() => (this.isLoading = false))

			if (!data) {
				return
			}
			// this.$router
			// 	.push({
			// 		query: params,
			// 	})
			// 	.catch()
			this.packageList = data.list
			this.pageConfig.total = Number(data.page.total)
			console.log(this.pageConfig.total)
		},
		getCondition() {
			let startTime = 0
			let endTime = 0
			const { keyword, code, type, date } = this.searchForm
			if (date) {
				const [start, end] = date
				startTime = Math.round(new Date(start).getTime() / 1000)
				endTime = Math.round(new Date(end).getTime() / 1000) + 86400
			}
			return {
				start_time: startTime,
				end_time: endTime,
				keyword,
				code,
				type,
			}
		},
		async beforeBatchPayment() {
			const selectedRows = this.selectedRows
			console.log(this.searchForm)
			if (selectedRows.length === 0) {
				const confrim = await ElMessageBox.confirm(
					'确认支付当前筛选条件下的所有包裹?',
					'提示',
					{
						distinguishCancelAndClose: false,
						confirmButtonText: '确认',
						cancelButtonText: '取消',
					}
				).catch(() => false)
				if (!confrim) {
					return
				}
			}
			const params = {
				...this.getCondition(),
				keyword: this.searchForm.keyword,
				code: this.searchForm.code,
				type: this.searchForm.type,
				package_ids: this.searchForm.packageIds.join(','),
			}
			const result: any = await request
				.get('/v1/merchants/unpaid-packages-info', {
					params: params,
				})
				.catch(() => null)
			if (!result) {
				return
			}
			const { data } = result
			this.paymentSummary.package_ids = data.package_ids
			this.paymentSummary.total_package = data.total_package
			this.paymentSummary.total_price = data.total_price
		},
		async createPayOrder() {
			const packageIds = this.paymentSummary.package_ids || []
			if (!packageIds.length) {
				return
			}
			const postData = {
				package_ids: packageIds,
				user_coupon_ids: [],
			}
			const { data } = await request
				.post('/v1/merchants/pay-order', postData)
				.catch(() => {
					return {
						data: null,
					}
				})
			if (!data) {
				return
			}
			for (const item of this.paymentDialog.paymentMethod) {
				if ('balance' == item.id) {
					item.note = `剩余(${data.user_balance})`
				}
			}
			this.paymentDialog.pay_order_id = data.pay_order_id
			this.paymentDialog.pay_order_amount = data.pay_order_amount
			this.paymentDialog.visible = true
		},
		async startPayment() {
			const { pay_order_id, selectPayment } = this.paymentDialog as any
			const loading = ElLoading.service({ fullscreen: true, text: '加载中...' })
			const { data } = await request
				.post('/v1/merchants/pay-order/pay', {
					pay_order_id,
					pay_type: selectPayment,
				})
				.catch(() => {
					return {
						data: null,
					}
				})
			nextTick(() => {
				loading.close()
			})
			if (!data) {
				return false
			}
			if ('success' == data && selectPayment == 'balance') {
				this.paymentDialog.visible = false
				ElMessage({
					showClose: true,
					message: '支付成功！',
					type: 'success',
				})
				this.searchPackages()
				return
			}
			const { code_url } = data as any
			this.paymentDialog.timer = window.setInterval(async () => {
				this.queryPayOrder(pay_order_id).then((res: any) => {
					if (res && res.status == 1) {
						window.clearInterval(this.paymentDialog.timer)
						this.paymentDialog.visible = false
						this.paymentDialog.qrcode = ''
						this.paymentDialog.showQrcode = false
						ElMessage({
							showClose: true,
							message: '支付成功！',
							type: 'success',
						})
						this.searchPackages()
					}
				})
			}, 1000)
			nextTick(() => {
				QRCode.toDataURL(code_url).then((url: any) => {
					this.paymentDialog.qrcode = url
					this.paymentDialog.showQrcode = true
					this.paymentDialog.visible = false
				})
			})
			setTimeout(() => {
				window.clearInterval(this.paymentDialog.timer)
				ElMessageBox.confirm('支付超时,请刷新页面重新发起支付', '提示', {
					showCancelButton: false,
					confirmButtonText: '确定',
					type: 'warning',
				})
				this.paymentDialog.showQrcode = false
				this.searchPackages()
			}, 1000 * 5)
		},
		async queryPayOrder(orderId: number) {
			const { data } = await request
				.get(`/v1/merchants/pay-order/${orderId}`)
				.catch(() => {
					window.clearInterval(this.paymentDialog.timer)
					return {
						data: null,
					}
				})
			return data
		},
		dialogClose() {
			this.$emit('close')
			this.$emit('update:visible', false)
			window.clearInterval(this.paymentDialog.timer)
			this.paymentDialog.visible = false
			this.paymentDialog.showQrcode = false
			this.searchPackages()
		},
	},
})
